/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
/* eslint-disable */

const mapWorkflowFromSdk = workflow => ({
  _id: workflow.id,
  id: workflow.id,
  name: workflow.name,
  availableRoles: workflow.availableRoles,
  steps: workflow.steps.map(step => ({
    _id: step.id,
    name: step.name !== 'primary_email' ? step.name : '',
    dueDate: step.dueDate,
    mandatoryDecisionThreshold: step.mandatoryDecisionThreshold,
    start: step.id === workflow.startFrom,
    users: step.users.map(user => ({
      email: user.email,
      role: user.role,
      permissions: {
        inviter: user.permissions.inviter,
      },
    })),
  })),
});

const getWorkflowTemplateProofOwners = id => (
  window.__pageproof_bridge__.backendService
    .fetch('workflow.permissions.team-list', { workflowId: id })
    .data()
    .then(data => data
      .filter(({ WorkflowCopyToProof }) => WorkflowCopyToProof === '1')
      .map(({ Email }) => Email))
);

export {
  mapWorkflowFromSdk,
  getWorkflowTemplateProofOwners,
};
