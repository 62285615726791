/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
/* eslint-disable */

import React, { Fragment, useEffect, useRef, useState } from 'react';
import css from './IndividualProofSetup.scss';

import CopyButton from '../../Button/CopyButton';
import FormControl from '../../FormControl';
import Textarea from '../../Textarea';
import Tags from '../../../containers/Tags';
import TextareaWithTools from '../../TextareaWithTools';
import Input from '../../Input';
import Switch from '../../Switch/Switch';
import DueDateAndReminders from './DueDateAndReminders';
import InputLimitCounter from '../../InputLimitCounter';
import Suggestions from '../../Suggestions';
import FloatingLabelDescription from './FloatingLabelDescription';
import getProof from '../utils/finalize/getProof';
import guardOnChangeLength from '../../../util/guardOnChangeLength';
import { MAX_PROOF_NAME_LENGTH, MAX_PROOF_REFERENCE_LENGTH } from '../../../util/constants';
import getProofFileType from '../utils/getProofFileType';
import { setNewVersionPreferredProofName, NEW_VERSION_PREFERRED_PROOF_NAME_TYPES } from '../utils/newVersionPreferredProofName';
import { Translation, TranslatedProps } from '../../Text';
import InputOptions from './InputOptions';
import IntegrationReferenceSelector from './IntegrationReferenceSelector'
import IntegrationReferences from '../../IntegrationReferences';
import CollectionInProofSetup from './CollectionInProofSetup';
import HelpBubble from '../../HelpBubble/HelpBubble';
import { addEmail} from '../utils/addFile';
import { Button } from '../../Button';
import Tooltip from '../../Tooltip';

const IndividualProofSetup = ({ proofs, parameters, shared, proof: sourceProof, userPreferences, userFeatures, onChange, onSharedChange, importedTags }) => {
  const messageToReviewersRef = useRef(null);
  const proof = getProof(sourceProof, shared, parameters, userPreferences);

  const handleChange = () => {
    proof._isEdited = true;
    onChange(proof);
  };

  const handleSharedChange = (shared) => {
    shared._isEdited = true;
    onSharedChange(shared);
  }

  // This is only to clear collection in IndividualProofSetup since we never call getShared() here.
  useEffect(() => {
    if (!parameters.addToCollection && !shared.collection) {
      shared.collection = '';
      onSharedChange(shared);
    }
  }, [parameters.addToCollection, shared.collection])

  const isBrief = parameters.proofType === 'brief';
  const proofFileType = getProofFileType(proof);
  const canChangeCanDownload = !isBrief && proofFileType !== 'web-url';
  const isEmailProof = proofFileType === 'email';
  const shouldDisplayEmailAddress = isEmailProof && ['uploading', 'ready', 'error'].indexOf(proof.file.status) !== -1;
  const labelPrefix = `create.${parameters.proofType}`;

  const [canChangeCommentVisibility] = useState(() => {
    return !isBrief && parameters.attachNewVersion
      ? proof.commentVisibility === 'commentCreatorOrProofTeam' || parameters.attachNewVersion.canHaveCommentVisibility
      : userFeatures.hasCommentVisibility;
  });
  const [canUpdateIsPublic] = useState(() => {
    return parameters.updateProof
      ? proof.isPublic || proof.canBePublic
      : userFeatures.hasPublicProofs;
  });

  return (
    <div className={css.IndividualProofSetup}>
      {(isEmailProof && shouldDisplayEmailAddress && !proof.isAwaitingEmail) && (
        <FormControl label={<Translation value={`${labelPrefix}.email-address`} />}>
          {proof.file.emailAddress && (
            <CopyButton
              variant="roundLightGreen"
              text={proof.file.emailAddress}
              className={css.IndividualProofSetup__copyEmailAddressButton}
            />
          )}
          <TranslatedProps placeholder="create.proof.email-address.placeholder">
            <Input
              variant="compact"
              readOnly
              value={proof.file.emailAddress || ''}
              onFocus={(event) => {
                event.target.select();
              }}
            />
          </TranslatedProps>
        </FormControl>
      )}
      {proof.isAwaitingEmail && (
        <FormControl label={<Translation value={`${labelPrefix}.email-address`} />}>
          <Tooltip 
          title={<Translation value="proof-info.message.awaiting-email.message.generate" />}
          >
            <Button
              variant="roundLightGreen"
              autoWidth
              className={css.IndividualProofSetup__copyEmailAddressButton}
              onClick={() => {
                proof.isAwaitingEmail = false;
                handleChange();
                addEmail(proof.id)
              }}
              label={<Translation value="proof-info.message.awaiting-email.button.generate" />}
            />
          </Tooltip>
            <Input
              variant="compact"
              disabled
              readOnly
              placeholder={'••••••••••'}
            />
        </FormControl>
      )}
      <FormControl
        label={<Translation value={`${labelPrefix}.name`} />}
      >
        <TextareaWithTools
          tools={
            isEmailProof
              ? (
                proof.name.length === 0 && (
                  <HelpBubble
                    message={<Translation value="create.proof.name.email-proof-description" />}
                  />
                )
              )
              : (
                <InputOptions
                  options={
                    <Fragment>
                      <InputOptions.Option
                        label={<Translation value="create.proof.name.use-file-name" />}
                        checked={proof._defaultName === NEW_VERSION_PREFERRED_PROOF_NAME_TYPES.filename}
                        onClick={() => {
                          if (parameters.attachNewVersion) {
                            setNewVersionPreferredProofName(NEW_VERSION_PREFERRED_PROOF_NAME_TYPES.filename);
                            sourceProof._defaultName = NEW_VERSION_PREFERRED_PROOF_NAME_TYPES.filename;
                          }

                          sourceProof.name = proof.file.name.substring(0, proof.file.name.indexOf('.'));
                          onChange(sourceProof);
                        }}
                      />
                      {(parameters.attachNewVersion && parameters.defaults.name) && (
                        <InputOptions.Option
                          label={<Translation value="create.proof.name.use-previous-version" />}
                          checked={!proof._defaultName}
                          onClick={() => {
                            setNewVersionPreferredProofName(null);
                            delete sourceProof._defaultName;

                            sourceProof.name = parameters.defaults.name;
                            onChange(sourceProof);
                          }}
                        />
                      )}
                    </Fragment>
                  }
                />
              )
          }
          variant="compact"
          autoFocus
          value={proof.name}
          onChange={guardOnChangeLength(proof.name, MAX_PROOF_NAME_LENGTH, (name) => {
            sourceProof.name = name.replace(/\n/g, '');
            onChange(sourceProof);
          })}
        />
        <InputLimitCounter
          value={proof.name}
          limit={MAX_PROOF_NAME_LENGTH}
        />
      </FormControl>
      {proofs.length === 1 && (
        <CollectionInProofSetup
          className={css.IndividualProofSetup__collectionName}
          shared={shared}
          canShareCollectionsWithTeam={userFeatures.canShareCollectionsWithTeam}
          onChange={handleSharedChange}
          helpBubble={!shared.collection}
        />
      )}
      <FormControl label={<Translation value={`${labelPrefix}.tags`} />}>
        <Tags
          tags={proof.tags}
          importedTags={importedTags}
          onChange={(tags) => {
            proof.tags = tags;
            handleChange();
          }}
        />
      </FormControl>
      {(userFeatures.hasProofReferences || typeof proof.reference === 'string') && !isBrief && (
        <Fragment>
          <FormControl
            label={<Translation value={`${labelPrefix}.reference`} />}
            after={
              <IntegrationReferences
                removeReference={referenceId => {
                  proof.integrationReferences = proof.integrationReferences.filter(reference => reference.referenceId !== referenceId);
                  handleChange();
                }}
                integrationReferences={proof.integrationReferences}
              />
            }
          >
            <TranslatedProps placeholder="create.proof.reference.placeholder">
              <TextareaWithTools
                tools={
                  <IntegrationReferenceSelector
                    isCurrentUserInProofTeam
                    onSelectReferenceOption={(reference) => {
                      if (proof.integrationReferences) {
                        proof.integrationReferences.push(reference);
                      } else {
                        proof.integrationReferences = [reference];
                      }
                      handleChange();
                    }}
                    integrationReferences={proof.integrationReferences || []}
                  />
                }
                variant="compact"
                value={proof.reference}
                onChange={guardOnChangeLength(proof.reference, MAX_PROOF_REFERENCE_LENGTH, (reference) => {
                  proof.reference = reference;
                  handleChange();
                })}
              />
            </TranslatedProps>
            <InputLimitCounter
              value={proof.reference}
              limit={MAX_PROOF_REFERENCE_LENGTH}
            />
          </FormControl>
        </Fragment>
      )}
      {proofs.length <= 1 && (isBrief
        ? (
          <FormControl label={<Translation value={`${labelPrefix}.message-to-recipient`} />}>
            <Textarea
              variant="compact"
              value={proof.messageToRecipient}
              onChange={(messageToRecipient) => {
                proof.messageToRecipient = messageToRecipient;
                handleChange();
              }}
            />
          </FormControl>
        )
        : (
          <FormControl
            label={<Translation value={`${labelPrefix}.message-to-reviewers`} />}
          >
            <TextareaWithTools
              tools={
                <InputOptions
                  options={
                    <Fragment>
                      <InputOptions.Option
                        label={<Translation value="create.proof.message-to-reviewers.use-my-default" />}
                        onClick={() => {
                          proof.messageToReviewers = userPreferences.messageToReviewers.value;
                          handleChange();
                        }}
                      />
                      {(parameters.attachNewVersion && parameters.defaults.messageToReviewers) && (
                        <InputOptions.Option
                          label={<Translation value="create.proof.message-to-reviewers.use-previous-version" />}
                          onClick={() => {
                            proof.messageToReviewers = parameters.defaults.messageToReviewers;
                            handleChange();
                          }}
                        />
                      )}
                      <InputOptions.Separator />
                      <InputOptions.Option
                        label={<Translation value="button.clear" />}
                        onClick={() => {
                          proof.messageToReviewers = '';
                          messageToReviewersRef.current.focus()
                          handleChange();
                        }}
                      />
                    </Fragment>
                  }
                />
              }
              ref={messageToReviewersRef}
              variant="compact"
              value={proof.messageToReviewers}
              onChange={(messageToReviewers) => {
                proof.messageToReviewers = messageToReviewers;
                handleChange();
              }}
            />
          </FormControl>
        )
      )}
      <FormControl label={<Translation value={`${labelPrefix}.due-date`} />}>
        <DueDateAndReminders
          dueDate={proof.dueDate}
          onDueDateChange={(dueDate) => {
            proof.dueDate = dueDate;
            handleChange();
          }}
          reminders={proof.reminders}
          onRemindersChange={(reminders) => {
            proof.reminders = reminders;
            handleChange();
          }}
          hasRemindersFeature={!isBrief}
          userPreferences={userPreferences}
          previousVersionDueDate={parameters.attachNewVersion && parameters.attachNewVersion.dueDate}
        />
      </FormControl>
      {canChangeCanDownload && (
        <FormControl label={<Translation value={`${labelPrefix}.can-download`} />}>
          <Switch
            value={proof.canDownload}
            onChange={(canDownload) => {
              proof.canDownload = canDownload;
              handleChange();
            }}
          />
        </FormControl>
      )}
      {isBrief && (
        <FormControl label={<Translation value={`${labelPrefix}.recipient`} />}>
          <Suggestions
            onSelect={(recipient) => {
              proof.recipient = recipient;
              handleChange();
            }}
          >
            <Input
              variant="compact"
              value={proof.recipient}
              onChange={(recipient) => {
                proof.recipient = recipient;
                handleChange();
              }}
            />
          </Suggestions>
        </FormControl>
      )}
      {canUpdateIsPublic && (
        <FormControl
          label={
            <Fragment>
              <Translation value={`${labelPrefix}.can-share-link`} />
              <a
                href="http://help.pageproof.com/en/articles/5251788-what-is-a-proof-share-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FloatingLabelDescription
                  description={<Translation value={`${labelPrefix}.can-share-link.help`} />}
                />
              </a>
            </Fragment>
          }
        >
          <Switch
            value={proof.isPublic}
            onChange={(isPublic) => {
              proof.isPublic = isPublic;
              handleChange();
            }}
          />
        </FormControl>
      )}
      {canChangeCommentVisibility && (
        <FormControl
          label={
            <Fragment>
              <Translation value="create.proof.invisible-reviewer-comments" />
              <a
                href="http://go.pageproof.com/help/invisible-reviewer-comments"
                target="_blank"
              >
                <FloatingLabelDescription
                  description={<Translation value="create.proof.invisible-reviewer-comments.help" />}
                />
              </a>
            </Fragment>
          }
        >
          <Switch
            value={proof.commentVisibility === 'commentCreatorOrProofTeam'}
            onChange={(commentVisibility) => {
              proof.commentVisibility = commentVisibility ? 'commentCreatorOrProofTeam' : null;
              handleChange();
            }}
          />
        </FormControl>
      )}
    </div>
  );
};

export default IndividualProofSetup;
