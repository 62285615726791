export default function commaSeparatedEmails(value, limit) {
  const emails = value
    .toLowerCase()
    .split(',')
    .map(email => email.trim())
    .filter(email => window.validateEmail(email))
    .filter(Boolean);

  if (typeof limit === 'number') {
    return emails.slice(0, limit);
  }

  return emails;
}
