/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
/* eslint-disable */

import React, { Fragment, useState, useCallback, useEffect, useRef, useMemo } from 'react';
import classname from 'classname';
import css from './ProofSetup.scss';

import { useProofSetup, getProofSetup, clearProofSetup, deleteProofSetupProof, deleteProofSetupProofFile, removeProofSetupValidation, addOrUpdateProofSetupValidation } from './utils/proofSetup';
import IndividualProofSetup from './components/IndividualProofSetup';
import SharedSetup from './components/SharedSetup';
import ExpandingSection from './components/ExpandingSection';
import Owners from './components/Owners';
import Workflow from './components/Workflow';
import ButtonGroup from '../ButtonGroup';
import Button from '../Button/Button';
import HeaderPortal from '../HeaderPortal';
import SelectFileOptions from './components/SelectFileOptions';
import HeaderBanner from './components/HeaderBanner';
import WorkflowChooser from './components/WorkflowChooser';
import SetupItem from './components/SetupItem';
import finalize from './utils/finalize';
import canFinalize from './utils/finalize/canFinalize';
import handleFinalizationValidation from './utils/finalize/handleFinalizationValidation';
import { useUserPreferences } from '../../hooks/useUserPreferences';
import { useUserFeatures } from '../../hooks/useUserFeatures';
import { useLocalStorage } from '../LocalStorage';
import Tooltip from '../Tooltip/Tooltip';
import Progress from '../Progress/Progress';
import DropAnywhere from './components/DropAnywhere';
import ProofCarousel from './components/ProofCarousel';
import ContainOverscrollBehaviour from './components/ContainOverscrollBehaviour';
import WhenSticky from './components/WhenSticky';
import Toaster from './components/Toaster';
import { Translation } from '../Text';
import { scheduleBackgroundTask } from '../../util/scheduleBackgroundTask';
import { sdk } from '../../util/sdk';
import { unique } from '../../util/object-array-utils';
import updateProcessingFileStatuses from './utils/updateProcessingFileStatuses';
import { saveWorkflowTemplate } from '../../features/workflowTemplates';
import addLocalFiles from './utils/addLocalFiles';
import ActionMessageModal from '../ActionMessageModal';
import { openModal } from '../../util/modal';
import useSkippedDialog from '../../hooks/useSkippedDialog';
import { ChecklistSection } from './components/ChecklistSection';
import { PeopleSection } from './components/PeopleSection';
import { ProofTemplatesContainer } from './components/ProofTemplates/ProofTemplatesContainer';
import { getWorkflowWithAvailableRoles } from './utils/getWorkflowWithAvailableRoles';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errorMessage: null,
      errorInfo: null,
      proofSetup: null,
    };
  }

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      errorMessage: error.stack.toString(),
      proofSetup: getProofSetup(),
    };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ errorInfo });

    if (window.Bugsnag) {
      window.Bugsnag.notifyException(error, {
        proof_setup: {
          state: JSON.stringify(getProofSetup(), null, '  '),
          componentStack: errorInfo.componentStack,
        },
      });
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={{ margin: '100px auto', maxWidth: 800 }}>
          <img src="/img/content/page-proof-oops.gif" style={{ width: '100%', marginTop: -100 }} />
          <h3 style={{ textAlign: 'center', color: '#555' }}>
            <Translation value="proof.setup.error-page.title" />
          </h3>
          <p style={{ fontSize: 16, textAlign: 'center', color: '#666' }}>
            <Translation value="proof.setup.error-page.description" />
          </p>
          <br />
          <ButtonGroup>
            <Button
              variant="outline"
              onClick={() => (window.localStorage.removeItem('pageproof.app.proof-setup'), location.reload())}
              label={<Translation value="button.discard-draft" />}
            />
            <Button
              autoWidth
              onClick={() => location.reload()}
              label={<Translation value="button.try-again" />}
            />
          </ButtonGroup>
          {process.env.NODE_ENV === 'development' && (
            <Fragment>
              <br />
              <br />
              <pre>{this.state.errorMessage}</pre>
              <pre>{this.state.errorInfo && this.state.errorInfo.componentStack}</pre>
              <pre style={{ whiteSpace: 'pre-wrap' }}>{'\n'}{JSON.stringify(this.state.proofSetup)}</pre>
            </Fragment>
          )}
        </div>
      );
      // return (
      //   <div style={{ margin: '100px auto', maxWidth: 800 }}>
      //     <h1>😿</h1>
      //     <pre>{this.state.errorMessage}</pre>
      //     <pre>{this.state.errorInfo && this.state.errorInfo.componentStack}</pre>
      //     <pre style={{ whiteSpace: 'pre-wrap' }}>{'\n'}{JSON.stringify(this.state.proofSetup)}</pre>
      //     <br />
      //     <ButtonGroup>
      //       <Button
      //         autoWidth
      //         onClick={() => location.reload()}
      //         label="Reload"
      //       />
      //       <Button
      //         onClick={() => (window.localStorage.removeItem('pageproof.app.proof-setup'), location.reload())}
      //         label="Reset &amp; Reload"
      //       />
      //     </ButtonGroup>
      //   </div>
      // );
    }
    return this.props.children;
  }
}

let Debug;

if (process.env.NODE_ENV === 'production') {
  Debug = () => <span />;
} else {
  Debug = ({ state, setState }) => {
    const [isDebug, setIsDebug] = useLocalStorage('pageproof.app.proof-setup.is-debug', false);
    const [edits, setEdits] = useState(null);

    return (
      <div style={{ position: 'fixed', bottom: 10, left: 10 }}>
        <Tooltip
          visible={isDebug}
          up
          right
          arrow
          offset={12}
          maxWidth={false}
          title={() => (
            <textarea
              style={{
                backgroundColor: '#333',
                color: '#fff',
                width: 900,
                height: 580,
                display: 'block',
                fontFamily: 'monospace',
                fontSize: 16,
                lineHeight: '24px',
                border: 0,
                resize: 'both',
                transition: 'none',
              }}
              onInput={(event) => {
                setEdits(event.target.value);
              }}
              onBlur={() => {
                try {
                  if (edits) {
                    const parsedState = JSON.parse(edits);
                    setEdits(null);
                    setState(parsedState);
                  }
                } catch (err) {
                  console.error(err);
                  // don't do anything
                }
              }}
              value={edits || JSON.stringify(state, null, '  ')}
            />
          )}
        >
          <button onClick={() => setIsDebug(!isDebug)}>Debug</button>
        </Tooltip>
        <button onClick={() => (setState(null), location.reload())}>Reset</button>
      </div>
    );
  };
}

const getFinalizeButtonLabel = (proofSetup) => {
  const numberOfProofs = proofSetup.proofs.length;
  const isSendingProofs = !!proofSetup.workflow;
  const hasMultipleProofs = numberOfProofs > 1;
  const isHandlingCollection = proofSetup.shared.collection;
  const isAddingToCollection = proofSetup.shared.addToCollection && proofSetup.shared.addToCollection.id;
  const isAttachingNewVersion = proofSetup.parameters.attachNewVersion;
  const isBrief = proofSetup.parameters.proofType === 'brief';
  const isUpdatingProof = !!proofSetup.parameters.updateProof;

  const currentUserEmail = sdk.session.user.email;
  const hasOtherOwners = proofSetup.owners.some(email => email !== currentUserEmail);

  if (isBrief) {
    return 'create.button.send-brief';
  } else if (isAttachingNewVersion) {
    return 'create.button.upload-new-version';
  } else if (isHandlingCollection) {
    if (isAddingToCollection) {
      return 'create.button.add-to-collection';
    } else if (isSendingProofs) {
      return 'create.button.send-collection';
    } else {
      return 'create.button.create-collection';
    }
  } else if (isUpdatingProof) {
    if (isSendingProofs) {
      return 'create.button.send-single-proof';
    } else {
      return 'create.button.update-proof';
    }
  } else if (isSendingProofs || hasOtherOwners) {
    if (hasMultipleProofs) {
      return 'create.button.send-multiple-proofs';
    } else {
      return 'create.button.send-single-proof';
    }
  } else {
    if (hasMultipleProofs) {
      return 'create.button.create-multiple-proofs';
    } else {
      return 'create.button.create-single-proof';
    }
  }
};

const ProofSetup = ({ onCreate }) => {
  const [state, setState, resetState] = useProofSetup();
  const [, userPreferences] = useUserPreferences();
  const [, userFeatures] = useUserFeatures();
  const [isLoadingWorkflow, setIsLoadingWorkflow] = useState(false);

  const [rawCurrentProofIndex, setCurrentProofIndex] = useState(state.proofs.length > 1 ? -1 : 0);
  const currentProofIndex = state.proofs.length === 1 ? 0 : Math.min(rawCurrentProofIndex, state.proofs.length - 1);

  const isBrief = state.parameters.proofType === 'brief';
  const isShared = currentProofIndex === -1;
  const currentProof = isShared ? state.shared : state.proofs[currentProofIndex];
  const canEditPeople = !isBrief && (isShared || state.proofs.length === 1);
  const hasMultipleProofs = state.proofs.length > 1;
  const canSelectMore = (!state.parameters.attachNewVersion && !state.parameters.updateProof) || state.proofs.length === 0;
  const canSelectMultiple = !isBrief && !state.parameters.attachNewVersion && !state.parameters.updateProof;

  const canAddProofTemplates = (
    userFeatures.hasProofTemplates &&
    (
      (hasMultipleProofs && isShared) ||
      !hasMultipleProofs ||
      (state.parameters.updateProof && state.parameters.updateProof.id)
    )
  );
  
  const disableAddFiles = state.currentProofTemplateId && !hasMultipleProofs;
 
  const showChecklistSection = useMemo(() => {
    if (state.parameters.updateProof) {
      return currentProof.canAddChecklist;
    }

    if (state.parameters.attachNewVersion) {
      return state.parameters.attachNewVersion.canAddChecklist;
    }

    return userFeatures.hasChecklists
  }, [state.parameters.updateProof, state.parameters.attachNewVersion, userFeatures.hasChecklists, currentProof]);

  const { PPProofDialogType } = window.__pageproof_bridge__;
  const { shouldBeSkipped } = useSkippedDialog(PPProofDialogType.MISSING_REVIEWER_OWNER);

  useEffect(() => {
    return scheduleBackgroundTask(updateProcessingFileStatuses);
  }, []);

  // useEffect(() => {
  //   if (state.workflow.id && !state.workflow.steps) {
  //     loadProofSetupWorkflow(state.workflow.id);
  //   }
  // }, [state.workflow.id, state.workflow.steps]);

  const updateShared = (shared) => {
    setState(state => ({ ...state, shared }));
  };

  const updateProof = (proof) => {
    state.proofs[currentProofIndex] = proof;
    setState(state => ({ ...state }));
  };

  const handleSaveWorkflow = () => {
    const workflow = {
      ...state.workflow,
      steps: state.workflow.steps.map(step => ({
        ...step,
        dueDate: null,
      }))
    };
    
    return saveWorkflowTemplate(workflow, state.owners)
      .then((workflowTemplate) => {
        setState(state => ({
          ...state,
          workflow: {
            ...state.workflow,
            id: workflowTemplate.id,
            name: workflowTemplate.name,
            _isEdited: false,
          },
        }));
      });
  };

  const promptMissingOwnerReviewerModal = () => {
    const { sdk } = window.__pageproof_bridge__;
    const userEmail = sdk.session.user.email;
    return (
      state.parameters.proofType !== 'brief' &&
      !shouldBeSkipped && !state.workflow &&
      (userEmail === state.owners[0] && state.owners.length === 1)
    );
  };

  const buttons = (
    <WhenSticky className={classname(css.ProofSetup__buttons, css['ProofSetup__buttons--sticky'])}>
      <div className={css.ProofSetup__buttons}>
        <ButtonGroup center>
          {isShared || !hasMultipleProofs
            ? (
              <Fragment>
                <Button
                  autoWidth
                  variant="text"
                  label={<Translation value="button.back" />}
                  onClick={() => {
                    const { $location, $rootScope, modalService } = window.__pageproof_bridge__;
                    const navigate = () => {
                      const backUrl = state.parameters.backUrl || '/dashboard';
                      $location.backward().url(backUrl);
                    };
                    modalService.create(
                      Translation.text('proof.setup.save-as-draft.title'),
                      Translation.text('proof.setup.save-as-draft.description'),
                      [
                        {
                          text: Translation.text('button.discard'),
                          type: 'text',
                          click: () => {
                            resetState();
                            navigate();
                          },
                        },
                        {
                          text: Translation.text('button.save-as-draft'),
                          type: 'primary',
                          click: navigate,
                        },
                      ],
                      {},
                      true,
                    );
                    $rootScope.$apply();
                  }}
                />
                <Button
                  key="finalize"
                  // disabled={!canFinalize(state)}
                  variant="primary"
                  label={
                    <Translation
                      value={getFinalizeButtonLabel(state)}
                      params={{ count: state.proofs.length }}
                    />
                  }
                  onClick={() => {
                    if (!canFinalize(state)) {
                      handleFinalizationValidation(state);
                    } else {
                      if (promptMissingOwnerReviewerModal()) {
                        const { destroy: close } = openModal(
                          <ActionMessageModal
                            title={<Translation value="create.proof.missing-owners-and-reviewers.title" />}
                            message={
                              <Translation
                                value={() => {
                                  if (state.shared.collection) {
                                    return 'create.proof.missing-owners-and-reviewers.collection';
                                  } else if (state.proofs.length > 1) {
                                    return 'create.proof.missing-owners-and-reviewers.multi';
                                  }
                                  return 'create.proof.missing-owners-and-reviewers.single';
                                }}
                              />
                            }
                            action={
                              <ButtonGroup align="center">
                                <Button
                                  variant="text"
                                  label={<Translation value="button.cancel" />}
                                  onClick={() => close()}
                                />
                                <Button
                                  variant="primary"
                                  label={<Translation value="button.agree-create" />}
                                  onClick={() => {
                                    onCreate(getProofSetup(), userPreferences);
                                    close();
                                  }}
                                />
                              </ButtonGroup>
                            }
                            dialogType={PPProofDialogType.MISSING_REVIEWER_OWNER}
                            canSkip
                          />,
                          null,
                          true
                        );
                      } else {
                        onCreate(state, userPreferences);
                      }
                    }
                  }}
                />
              </Fragment>
            )
            : (
              <Button
                key="shared-setup"
                variant="outline"
                label={<Translation value="button.back-to-shared-setup" />}
                onClick={() => setCurrentProofIndex(-1)}
              />
            )
          }
        </ButtonGroup>
      </div>
    </WhenSticky>
  );

  return (
    <Fragment>
      <div className={css.ProofSetup__toasterWrapper}>
        <Toaster
          render={{
            unsupportedFileType: ({ extensions }) => (
              extensions.length > 1
                ? <Translation value="proof.setup.error.unsupported-file-type.multi" params={{fileTypes: extensions.map((extension, index) => (<Fragment key={extension}><em>{extension}</em>{index !== extensions.length - 1 ? ', ' : ''}</Fragment>))}} />
                : <Translation value="proof.setup.error.unsupported-file-type" params={{fileType: <em>{extensions[0]}</em>}} />
            ),
            awaitingFile: () => <Translation value="proof.setup.error.awaiting-file" />,
            noProofs: () => <Translation value="proof.setup.error.no-proof" />,
            workflowIsInvalid: () => <Translation value="proof.setup.error.workflow-needs-step" />,
            noOwner: () => state.proofs.length > 1 ? <Translation value="proof.setup.error.no-owner.multi" /> : <Translation value="proof.setup.error.no-owner" />,
            invalidRecipient: () => <Translation value="proof.setup.error.brief-needs-recipient"/>,
            exceededMaxFileSize: ({ maximumFileSize }) => <Translation value="proof.setup.error.file-size" params={{fileSize: window.bytesToSize(maximumFileSize)}} />,
            fileDropDisabled: () => <Translation value="proof.setup.multiple.upload-file-disabled" />
          }}
          toasts={state.validation}
          onDismiss={(validation) => {
            setState(state => ({ ...state, validation }));
          }}
        />
      </div>
      <div
        className={classname(css.ProofSetup, {
          [css['ProofSetup--withPeople']]: canEditPeople,
        })}
      >
        <style>{`
          #gift-menu,
          #notification-tray,
          #user-status-menu {
            display: none;
          }
          .slide {
            padding-bottom: 0 !important;
          }
        `}</style>
        <DropAnywhere
          onFiles={(files) => {
            if (canSelectMore) {
              addLocalFiles(files, undefined, state.parameters, true);
            }
          }}
          disabled={disableAddFiles}
          onDisabledDrop={() => addOrUpdateProofSetupValidation('fileDropDisabled')}/>
        <ContainOverscrollBehaviour />
        <HeaderPortal position="center-middle">
        {canAddProofTemplates && 
          <ProofTemplatesContainer 
            setState={setState}
            state={state}
            currentProofIndex={currentProofIndex}
            hasMultipleProofs={hasMultipleProofs}
            setIsLoadingWorkflow={setIsLoadingWorkflow}
            userPreferences={userPreferences}
            isShared={isShared}
          />
        }
          <HeaderBanner walkthroughHook={hasMultipleProofs ? 'proof-setup-files' : 'proof-setup-file'}>
            {canAddProofTemplates && <div className={css.ProofSetup__proofTemplatesPositioningDiv} />}
            {hasMultipleProofs && (
              <SetupItem
                icon={<SetupItem.CollectionIcon />}
                title={<Translation value="proof.setup.multiple.shared-header" />}
                description={<Translation value="proof.count.multi" params={{count: state.proofs.length}} />}
                isSelected={currentProofIndex === -1}
                isCompact={currentProofIndex !== -1}
                onClick={() => setCurrentProofIndex(-1)}
                options={currentProofIndex === -1 &&
                  <SetupItem.ExpandOption
                    onClick={() => setCurrentProofIndex(0)}
                  />
                }
              />
            )}
            {currentProofIndex !== -1 && (
              <ProofCarousel
                shared={state.shared}
                proofs={state.proofs}
                parameters={state.parameters}
                userPreferences={userPreferences}
                currentIndex={currentProofIndex}
                onCurrentIndexChange={setCurrentProofIndex}
                onProofFileRemove={(id) => deleteProofSetupProofFile(id)}
                onProofRemove={(id) => deleteProofSetupProof(id)}
              />
            )}
            {canSelectMore && (
              <Fragment>
                <div style={{ width: 15 }} />
                <SelectFileOptions
                  direction="down"
                  onSelect={(count) => {
                    if (!hasMultipleProofs) {
                      setCurrentProofIndex(-1);
                    } else {
                      // Select the first new proof that has just been added...
                      setCurrentProofIndex((state.proofs.length + 1) - (count - 1));
                    }
                  }}
                  parameters={state.parameters}
                  multiple={canSelectMultiple}
                  tooltipTitle={!disableAddFiles 
                    ? <Translation value="proof.setup.multiple.upload-file" /> 
                    : <Translation value="proof.setup.multiple.upload-file-disabled" />}
                  disabled={disableAddFiles}
                >
                  <div>
                    <HeaderBanner.IconButton
                      size="large"
                      src="/img/icons/file-dropper.svg"
                      translateY={2}
                      active
                      disabled={disableAddFiles}
                    />
                  </div>
                </SelectFileOptions>
              </Fragment>
            )}
          </HeaderBanner>
        </HeaderPortal>
        <Debug
          state={state}
          setState={setState}
        />
        <div className={css.ProofSetup__page}>
          <div className={css.ProofSetup__container}>
            <div 
             className={css.ProofSetup__info}
             data-walkthrough-hook={userFeatures.hasProofReferences ? 'proof-setup-info-with-reference' : 'proof-setup-info'}
            >
              {isShared
                ? <SharedSetup
                  parameters={state.parameters}
                  shared={state.shared}
                  userPreferences={userPreferences}
                  userFeatures={userFeatures}
                  onChange={updateShared}
                  importedTags={state.proofTemplateValues && state.proofTemplateValues.tags || []}
                />
                : <IndividualProofSetup
                  key={currentProof._id}
                  proofs={state.proofs}
                  parameters={state.parameters}
                  shared={state.shared}
                  proof={currentProof}
                  userPreferences={userPreferences}
                  userFeatures={userFeatures}
                  onChange={updateProof}
                  onSharedChange={updateShared}
                  importedTags={state.proofTemplateValues && state.proofTemplateValues.tags || []}
                />
              }
              {!canEditPeople && buttons}
            </div>
            {canEditPeople && (
              <div className={css.ProofSetup__people}>
                <PeopleSection
                  walkthroughHook="proof-setup-reviewers"
                  preview={(open) => (
                    <WorkflowChooser
                      count={
                        state.workflow &&
                        state.workflow.steps &&
                        state.workflow.steps.reduce((count, step) => count + step.users.length, 0) ||
                        undefined
                      }
                      hasWorkflow={!!state.workflow}
                      workflow={state.workflow}
                      onChangeWorkflow={(workflow) => {
                        setState(state => ({ ...state, workflow }));
                        removeProofSetupValidation('workflowIsInvalid');
                      }}
                      previousVersionWorkflowId={state.parameters.attachNewVersion ? state.parameters.attachNewVersion.workflowId : undefined}
                      currentWorkflowId={state.workflow && state.workflow.id}
                      isEdited={!!(state.workflow && state.workflow.id && state.workflow._isEdited)}
                      canSaveWorkflow={state.workflow && canFinalize.isWorkflowValid(state)}
                      onWorkflow={({ workflow, owners }) => {
                        const manualOwners = state.owners.filter(owner => state.workflowTemplateOwners.indexOf(owner) === -1);
                        const workflowTemplateOwners = [];
                        owners.forEach((owner) => {
                          if (manualOwners.indexOf(owner) === -1) {
                            workflowTemplateOwners.push(owner);
                          }
                        });

                        const transformedWorkflow = getWorkflowWithAvailableRoles(workflow, state.parameters);
                        
                        setState(state => ({
                          ...state,
                          workflowTemplateOwners,
                          workflow: transformedWorkflow,
                          owners: unique([
                            ...manualOwners,
                            ...workflowTemplateOwners,
                          ]),
                        }));
                        open(!!workflow);
                      }}
                      onSaveWorkflow={handleSaveWorkflow}
                      isLoadingWorkflow={isLoadingWorkflow}
                      setIsLoadingWorkflow={setIsLoadingWorkflow}
                    />
                  )}
                  open={!!(state.workflow && state.workflow.steps)}
                  canOpen={!!(state.workflow && state.workflow.steps)}
                  readOnly={state.parameters.showWorkflowReadonly}
                >
                  <Workflow
                    type="proofSetup"
                    owners={state.owners}
                    workflow={state.workflow}
                    onChange={(workflow) => {
                      setState(state => ({ ...state, workflow }));
                      removeProofSetupValidation('workflowIsInvalid');
                    }}
                  />
                </PeopleSection>
                <PeopleSection
                  walkthroughHook="proof-setup-owners"
                  preview={
                    <Owners.Heading
                      count={state.owners.length}
                    />
                  }
                  open={state.owners.length > 1}
                  canOpen
                >
                  <Owners
                    owners={state.owners}
                    workflowTemplateOwners={state.workflowTemplateOwners}
                    proofTemplateOwners={state.proofTemplateValues && state.proofTemplateValues.owners}
                    onChange={(owners) => {
                      setState(state => ({
                        ...state,
                        workflowTemplateOwners: state.workflowTemplateOwners.filter(owner => owners.indexOf(owner) !== -1),
                        proofTemplateOwners: state.proofTemplateValues &&
                        state.proofTemplateValues.owners &&
                        state.proofTemplateValues.owners.filter(owner => owners.indexOf(owner) !== -1 && state.workflowTemplateOwners.indexOf(owner) === -1),
                        owners,
                      }));
                      removeProofSetupValidation('noOwner');
                    }}
                  />
                </PeopleSection>
                {showChecklistSection && (
                  <ChecklistSection
                    state={state}
                    setState={setState}
                  />
                )}
                {buttons}
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default () => {
  const [mode, setMode] = useState('setup');
  const [progress, setProgress] = useState(null);
  const [collection, setCollection] = useState(null);
  const [proofs, setProofs] = useState(null);

  const finalRef = useRef(null);

  const onCreateCallback = useCallback((percent) => {
    const proofSetup = finalRef.current;
    setProgress({
      progress: percent,
      status: percent === 100 ? 2 : 1,
      total: proofSetup.proofs.length,
      type: proofSetup.parameters.proofType,
    });
  });

  const onCreate = (proofSetup, userPreferences) => {
    finalRef.current = proofSetup;
    setMode('progress');
    onCreateCallback(0);
    finalize(proofSetup, userPreferences, onCreateCallback)
      .then(({ collection, proofs }) => {
        setCollection(collection);
        setProofs(proofs);
      });
  };

  useEffect(() => {
    if (mode === 'progress') {
      clearProofSetup();
    }
  }, [mode]);

  return (
    <ErrorBoundary>
      {mode === 'setup' && <ProofSetup onCreate={onCreate} />}
      {mode === 'progress' && (
        <Progress
          {...progress}
          collectionId={collection && collection.id}
          proofs={proofs}
        />
      )}
    </ErrorBoundary>
  );
};
