/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
/* eslint-disable */

import React, { cloneElement, Children, useRef, useState, useEffect, Fragment } from 'react';
import InlineSVG from 'jacobmarshall-react-inline-svg';
import css from './Suggestions.scss';

import Tooltip from '../Tooltip/Tooltip';
import { OptionList, Option, Separator } from '../PopupMenu';
import Avatar from '../Avatar/Avatar';
import HighlightedText from '../HighlightedText/HighlightedText';
import env from '../../../../shared/env';
import useDarkMode from '../../hooks/useDarkMode';
import useTeamMemberSuggestions from '../../hooks/useTeamMemberSuggestions';

const DEFAULT_SELECTED_INDEX = -1;

// TODO: Move this into its own file
const SuggestionLabel = (props) => {
  const {
    suggestion,
    source,
    inputValue,
    onRemove,
  } = props;

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <div className={css.Suggestion__avatar}>
        <Avatar
          active
          size={22}
          url={`${env.avatar_url}/-/${suggestion}`}
          spinner
        />
      </div>
      <div className={css.Suggestion__email}>
        <div className={css.Suggestion__email__inner}>
          <HighlightedText
            parentText={suggestion}
            subtext={inputValue}
          />
        </div>
      </div>
      {source === 'addressBook' && (
        <Tooltip
          up
          center
          title="Remove from address book"
        >
          <button
            className={css.Suggestion__remove}
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              onRemove(suggestion);
            }}
          >
            <InlineSVG
              src="/img/interface/menu-close-icon.svg"
              className={css.Suggestion__removeIcon}
            />
          </button>
        </Tooltip>
      )}
    </div>
  )
}

export default function Suggestions({
  inputRef: parentInputRef,
  exclusions = [],
  maxSuggestions = 5,
  onSelect,
  children,
  inputId,
}) {
  const {
    $addressBook: addressBook,
    addressBookRepositoryService: addressBookRepository,
    UserService: userService,
  } = window.__pageproof_bridge__;

  const [isDarkMode] = useDarkMode();
  const [focused, setFocused] = useState(false);
  const [fieldId] = useState(() => inputId || `search_${window.randomId('_')}`);
  let [selectedIndex, setSelectedIndex] = useState(-1);
  const inputRef = useRef();

  const teamMemberSuggestions = useTeamMemberSuggestions();
  const [suggestions, setSuggestions] = useState([]);
  const [filteredTeamMembers, setFilteredTeamMembers] = useState([]);
  const [filteredAddressBook, setFilteredAddressBook] = useState([]);

  const filtered = [...filteredAddressBook, ...filteredTeamMembers];

  if (filtered.length === 1) {
    selectedIndex = 0;
  }

  const child = Children.only(children);

  const calculate = () => {
    if (!inputRef.current || !focused) {
      return;
    }

    const value = inputRef.current.value.toLowerCase();

    if (value.length) {
      const newFilteredAddressBook = [];
      const newFilteredTeamMembers = [];
      suggestions.some((suggestion) => {
        const lowerSuggestion = suggestion.toLowerCase();
        if (lowerSuggestion.indexOf(value) !== -1 && exclusions.indexOf(lowerSuggestion) === -1 && lowerSuggestion !== value) {
          newFilteredAddressBook.push(suggestion);
        }
        return newFilteredAddressBook.length >= maxSuggestions;
      });
      if (newFilteredAddressBook.length < maxSuggestions) {
        teamMemberSuggestions.some((suggestion) => {
          const lowerSuggestion = suggestion.toLowerCase();
          if (lowerSuggestion.indexOf(value) !== -1 && exclusions.indexOf(lowerSuggestion) === -1 && lowerSuggestion !== value && !newFilteredAddressBook.some(addressBookSuggestion => addressBookSuggestion.toLowerCase() === lowerSuggestion)) {
           
            newFilteredTeamMembers.push(suggestion);
          }
          return (newFilteredAddressBook.length + newFilteredTeamMembers.length) >= maxSuggestions;
        });
      }
      setFilteredAddressBook(newFilteredAddressBook);
      setFilteredTeamMembers(newFilteredTeamMembers);
    } else {
      setFilteredAddressBook([]);
      setFilteredTeamMembers([]);
      setSelectedIndex(DEFAULT_SELECTED_INDEX);
    }
  };

  useEffect(calculate, [
    focused,
    exclusions.join(','),
    suggestions.join(','),
    teamMemberSuggestions.join(','),
  ]);

  useEffect(() => {
    addressBook.load(setSuggestions);

    const { userId } = userService.GetUserData();
    const offRemove = addressBookRepository.cache.on('remove', userId, () => {
      addressBook.load(setSuggestions);
    });
    const offUpdate = addressBookRepository.cache.on('update', userId, () => {
      addressBook.load(setSuggestions);
    });

    return () => {
      offRemove();
      offUpdate();
    };
  }, []);

  const removeSuggestion = (email) => {
    const updatedSuggestions = [...suggestions];
    updatedSuggestions.splice(suggestions.indexOf(email), 1);

    setSuggestions(updatedSuggestions);
    addressBook.delete(email);
  };

  const navigate = (direction) => {
    let newSelectedIndex = (selectedIndex + direction) % filtered.length;
    if (newSelectedIndex < DEFAULT_SELECTED_INDEX) newSelectedIndex = filtered.length - 1;
    setSelectedIndex(newSelectedIndex);
  };

  const select = (suggestion) => {
    onSelect(suggestion);
    setFilteredAddressBook([]);
    setFilteredTeamMembers([]);
    setSelectedIndex(DEFAULT_SELECTED_INDEX);
  };

  const variant = isDarkMode ? 'dark' : 'light';

  return (
    <Tooltip
      title={
        <OptionList variant={variant}>
          {!!filteredAddressBook.length && (
            <Fragment>
              <Option
                disabled
                label="Address book"
              />
          {filteredAddressBook.map((suggestion, index) => (
            <Option
              key={suggestion}
              selected={index === selectedIndex}
              label={
                <SuggestionLabel
                  suggestion={suggestion}
                  source="addressBook"
                  inputValue={inputRef.current.value.toLowerCase()}
                  onRemove={removeSuggestion}
                />
              }
              onMouseDown={(event) => {
                event.preventDefault();
              }}
              onClick={(event) => {
                event.preventDefault();
                select(suggestion);
              }}
            />
          ))}
          </Fragment>
        )}
          {!!filteredTeamMembers.length && (
            <Fragment>
              <Separator
                variant="light"
                fullWidth
              />
              <Option
                disabled
                label="Team members"
              />
              {filteredTeamMembers.map((suggestion, index) => (
                <Option
                  key={suggestion}
                  selected={(filteredAddressBook.length + index) === selectedIndex}
                  label={
                    <SuggestionLabel
                      suggestion={suggestion}
                      source="teamMembers"
                      inputValue={inputRef.current.value.toLowerCase()}
                    />
                  }
                  onMouseDown={(event) => {
                    event.preventDefault();
                  }}
                  onClick={(event) => {
                    event.preventDefault();
                    select(suggestion);
                  }}
                />
              ))}
            </Fragment>
          )}
        </OptionList>
      }
      variant={variant}
      visible={focused && filtered.length !== 0}
      delay={0}
    >
      {cloneElement(child, {
        // start: safari contact auto-fill fix
        id: fieldId,
        autoComplete: 'no-thank-you',
        autoCorrect: 'off',
        autoCapitalize: 'none',
        spellcheck: 'false',
        // end

        ref: (element) => {
          if (element instanceof HTMLElement) {
            if (parentInputRef) {
              parentInputRef.current = element; // eslint-disable-line no-param-reassign
            }
            inputRef.current = element;
          }
        },
        inputRef: (element) => {
          if (element instanceof HTMLElement) {
            inputRef.current = element;
          }
        },
        onChange: (event) => {
          if (child.props.onChange) {
            child.props.onChange(event);
          }
          calculate();
        },
        onFocus: (event) => {
          if (child.props.onFocus) {
            child.props.onFocus(event);
          }
          setFocused(true);
        },
        onBlur: (event) => {
          if (child.props.onBlur) {
            child.props.onBlur(event);
          }
          setFocused(false);
        },
        onKeyDown: (event) => {
          if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
            event.preventDefault();
            navigate(event.key === 'ArrowDown' ? +1 : -1);
          }
          else if (event.key === 'Enter') {
            const suggestion = filtered[selectedIndex];
            if (suggestion) {
              event.preventDefault();
              select(suggestion);
              return;
            }
          }
          else if (event.key === 'Escape') {
            event.preventDefault();
            setFilteredAddressBook([]);
            setFilteredTeamMembers([]);
          } else if (event.key === 'Tab') {
            const suggestion = filtered[selectedIndex];
            if (filtered.length !== 0 && suggestion) {
              event.preventDefault();
              select(suggestion);
            }
          }

          if (child.props.onKeyDown) {
            child.props.onKeyDown(event);
          }
        },
      })}
    </Tooltip>
  );
}

export function addEmail(email) {
  const { $addressBook, sdk } = window.__pageproof_bridge__;

  return new Promise((resolve) => {
    if (email) {
      $addressBook.load((emails) => {
        if (emails.indexOf(email) === -1) {
          sdk.users.byEmail(email).then(() => {
            $addressBook.add(email, () => {
              resolve();
            });
          });
        } else {
          resolve();
        }
      });
    } else {
      resolve();
    }
  });
}

Suggestions.addEmail = addEmail;
