/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { deleteBlob } from './temporaryReferences';
import generateThumbnail from './generateThumbnail';
import getTagsFromFilename from '../../../util/generate-initial-tags';
import { getProofSetup, saveProofSetup, updateProofSetup, updateProofSetupProof, addOrUpdateProofSetupValidation, getDefaults } from './proofSetup';
import generateEmailIcon from './generateEmailIcon';
import randomId from './randomId';
import { startLocalFileUpload, createWebURLFile, importExternalFile, createEmailFile } from './upload';
import { MAX_PROOF_NAME_LENGTH } from '../../../util/constants';
import { getNewVersionPreferredProofName, NEW_VERSION_PREFERRED_PROOF_NAME_TYPES } from './newVersionPreferredProofName';
import { supportedFileTypes, supportedFileTypesAfterTransform } from './supportedFileTypes';
import ellipsisTruncate from './ellipsisTruncate';
import { unique } from '../../../util/object-array-utils';
import env from '../../../../../shared/env';

// statuses: preparing -> uploading -> processing -> done/error/ready

function addFileToProof(replaceExisting, initialFileState, newProofState, parameters) {
  if (replaceExisting) {
    if (typeof replaceExisting === 'string') {
      updateProofSetupProof(replaceExisting, (proof) => {
        if (proof) {
          // eslint-disable-next-line no-param-reassign
          proof.file = initialFileState;
        }
      });

      return replaceExisting;
    } else {
      const proofSetup = getProofSetup();
      const [proof] = proofSetup.proofs;

      if (proof) {
        proof._id = proof._id || proof.id;
        proof.file = initialFileState;

        saveProofSetup(proofSetup);
        return proof._id;
      }
    }
  }

  const id = randomId();
  const defaults = getDefaults();

  const proof = {
    ...newProofState,
    ...defaults,
    file: initialFileState,
    _id: id,
  };

  if (parameters && parameters.attachNewVersion) {
    const newVersionPreferredProofName = getNewVersionPreferredProofName();

    if (newVersionPreferredProofName === NEW_VERSION_PREFERRED_PROOF_NAME_TYPES.filename && newProofState.name) {
      proof._defaultName = newVersionPreferredProofName;
      proof.name = newProofState.name;
    }
  }

  // Ensure the proof name is truncated if it's too long
  proof.name = ellipsisTruncate(proof.name, MAX_PROOF_NAME_LENGTH);

  updateProofSetup((proofSetup) => {
    proofSetup.proofs.push(proof);
  });
  return id;
}

export function addEmail(replaceExisting) {
  const id = addFileToProof(replaceExisting, {
    type: 'email',
    status: 'preparing',
    icon: generateEmailIcon(),
  }, {
    name: '',
    tags: [],
  });

  createEmailFile(id);

  return true;
}

export function generateEmailIconForProof() {
  return generateEmailIcon();
}

export function addExternalFile(provider, url, filename, size, bearerToken, replaceExisting, parameters) {
  const extensionPosition = filename.lastIndexOf('.');
  const name = filename.substring(0, extensionPosition);
  const extension = filename.substring(extensionPosition + 1);

  const id = addFileToProof(replaceExisting, {
    type: 'external',
    status: 'preparing',
    size,
    url,
    bearerToken,
    provider,
    name: filename,
  }, {
    name,
    tags: getTagsFromFilename(name),
  }, parameters);

  importExternalFile(id, provider, url, filename, bearerToken);

  if (window.fetch) {
    window.fetch(url)
      .then(response => response.blob())
      .then((blob) => {
        generateThumbnail(blob, extension).then((thumbnailReference) => {
          updateProofSetupProof(id, (matchingProof) => {
            if (matchingProof && matchingProof.file) {
              matchingProof.file.thumbnailReference = thumbnailReference; // eslint-disable-line no-param-reassign
            } else {
              deleteBlob(thumbnailReference); // Same as above, with the original file.
            }
          });
        });
      });
  }

  return true;
}

export function addExistingFile(file, replaceExisting) {
  addFileToProof(replaceExisting, {
    id: file.id,
    name: file.name,
    size: file.size,
    // Set any status other than 'OK' to 'processing' so that we continue polling for the file's status
    status: file.status === 'OK' ? 'ok' : 'processing',
    type: 'local',
  });
}

export function addWebURL(url, basicAuth, replaceExisting, parameters) {
  const id = addFileToProof(replaceExisting, {
    type: 'web-url',
    status: 'preparing',
    url,
    basicAuth,
  }, {
    name: ellipsisTruncate(url, MAX_PROOF_NAME_LENGTH),
    tags: [new URL(url).hostname],
  }, parameters);

  createWebURLFile(id, url, basicAuth);

  return true;
}

export function addLocalFile(blob, filename, replaceExisting, parameters, fileTypeOverride) {
  const extensionPosition = filename.lastIndexOf('.');
  const name = filename.substring(0, extensionPosition);
  const extension = filename.substring(extensionPosition + 1).toLowerCase();

  if (!supportedFileTypes.includes(extension) && !supportedFileTypesAfterTransform.includes(extension)) {
    const { SegmentIo } = window.__pageproof_bridge__;

    SegmentIo.track(65, {
      'file extension': extension,
      type: 'proof file',
    });

    addOrUpdateProofSetupValidation('unsupportedFileType', props => ({
      extensions: unique([
        ...(props ? props.extensions : []),
        extension,
      ]),
    }));
    return false;
  }

  if (blob.size > Number(env.max_upload_size || 524288000)) {
    addOrUpdateProofSetupValidation('exceededMaxFileSize', { maximumFileSize: Number(env.max_upload_size || 524288000) });
    return false;
  }

  const id = addFileToProof(replaceExisting, {
    type: 'local',
    status: 'preparing',
    size: blob.size,
    name: filename,
  }, {
    name,
    tags: getTagsFromFilename(name),
  }, parameters);

  if (supportedFileTypesAfterTransform.includes(extension)) {
    window.__pageproof_bridge__.zipService.prepare()
      .then(() => {
        window.__pageproof_bridge__.zipService.compress({ [filename]: blob })
          .then((compressedBlob) => {
            // eslint-disable-next-line no-undef
            const compressedFile = new File([compressedBlob], `${filename}.zip`, { type: 'application/zip' });

            updateProofSetupProof(id, (proof) => {
              if (proof) {
                // eslint-disable-next-line no-param-reassign
                proof.file = {
                  ...proof.file,
                  size: compressedFile.size,
                  name: compressedFile.name,
                };
              }
            });
            startLocalFileUpload(id, compressedFile, compressedFile.name, fileTypeOverride);
          });
      });

    return true;
  }

  startLocalFileUpload(id, blob, filename, fileTypeOverride);

  // Temporarily disabled - to investigate customer reports of uploads failing to complete due to local storage.
  // storeBlob(blob).then((fileReference) => {
  //   updateProofSetupProof(id, (matchingProof) => {
  //     if (matchingProof && matchingProof.file) {
  //       matchingProof.file.fileReference = fileReference; // eslint-disable-line no-param-reassign
  //     } else {
  //       // Cleanup if it took too long to upload the file to IndexedDB, and the proof no longer exists in the setup state.
  //       // If this doesn't occur, the file is subject to the default expiration of the temporaryReferences module (1 week).
  //       deleteBlob(fileReference);
  //     }
  //   });
  // });

  generateThumbnail(blob, extension).then((thumbnailReference) => {
    updateProofSetupProof(id, (matchingProof) => {
      if (matchingProof && matchingProof.file) {
        matchingProof.file.thumbnailReference = thumbnailReference; // eslint-disable-line no-param-reassign
      } else {
        deleteBlob(thumbnailReference); // Same as above, with the original file.
      }
    });
  });

  return true;
}
